window.showToast = function (message, icon = 'fa-solid fa-info-circle', width = 'max-w-sm', autoClose = true) {
    // Cria o elemento do toast com base no tipo (success, danger, info, etc.)
    const closeButtonHTML = `
        <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-primary-500 text-slate-50 rounded-lg p-1.5 inline-flex items-center justify-center h-8 w-8" aria-label="Close">
        <span class="sr-only">Close</span>
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
        </svg>
    </button>`;

    const toastHTML = `
    <div id="toast-notify-div"
         class="flex z-50 items-center w-full ${width} p-4 space-x-4 rtl:space-x-reverse text-slate-800 bg-white divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow opacity-0 transition-opacity duration-500 ease-in-out"
         role="alert">
        <i class="${icon}"></i>
        <div class="ps-4 font-semibold flex-grow">${message}</div>
        ${!autoClose ? closeButtonHTML : ''}
    </div>`;

    // Adiciona o toast ao container
    const toastContainer = document.getElementById('toast-container');
    const toastElement = document.createElement('div');
    toastElement.innerHTML = toastHTML;

    // Adiciona o toast ao container
    toastContainer.appendChild(toastElement);

    // Aguarda um pequeno tempo para garantir que o elemento foi inserido e depois aplica a classe para torná-lo visível
    setTimeout(() => {
        toastElement.firstElementChild.classList.remove('opacity-0');
        toastElement.firstElementChild.classList.add('opacity-100');
    }, 10);

    // Adiciona funcionalidade ao botão de fechar, se existir
    if (!autoClose) {
        const closeButton = toastElement.querySelector('button');
        if (closeButton) {
            closeButton.addEventListener('click', () => {
                toastElement.firstElementChild.classList.remove('opacity-100');
                toastElement.firstElementChild.classList.add('opacity-0');
                setTimeout(() => {
                    if (toastContainer.contains(toastElement)) {
                        toastContainer.removeChild(toastElement);
                    }
                }, 500); // Tempo para a transição de desaparecimento (deve ser igual ao valor de `duration-500`)
            });
        }
    } else {
        // Remove o toast após 5 segundos com uma transição suave
        setTimeout(() => {
            if (toastContainer.contains(toastElement)) {
                toastElement.firstElementChild.classList.remove('opacity-100');
                toastElement.firstElementChild.classList.add('opacity-0');
                setTimeout(() => {
                    if (toastContainer.contains(toastElement)) {
                        toastContainer.removeChild(toastElement);
                    }
                }, 500); // Tempo para a transição de desaparecimento (deve ser igual ao valor de `duration-500`)
            }
        }, 5000);
    }
}
