import { Modal } from 'flowbite';

document.addEventListener("DOMContentLoaded", async () => {
    if (document.getElementById("fingerprintAtual")) {

        let deviceId = document.getElementById("fingerprintAtual").value;

        Echo.channel(`device-inactivity.${deviceId}`)
            .listen('DeviceInactivityWarning', (e) => {
                console.log('DeviceInactivityWarning Echo :: ', e);
                showModal(e.message);
            });

        // Função para mostrar o modal
        window.showModal = (message) => {
            const $targetEl = document.getElementById('activity-confirmation');
            const options = {
                placement: 'bottom-right',
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
                closable: true,
            };

            const modal = new Modal($targetEl, options);

            // Atualiza a mensagem do modal
            const modalMessage = document.getElementById('activity-confirmation-message');
            modalMessage.innerText = message;

            // Exibe o modal
            modal.show();

            // Inicia a contagem regressiva
            startCountdown(59);

            const timeoutId = setTimeout(() => {
                axios.post('/disconnect-device', {
                    device_id: deviceId
                }).then(() => {
                    location.reload();
                    console.log('Dispositivo desconectado por inatividade.');
                })
                    .catch((error) => {
                        console.error('Erro ao desconectar dispositivo:', error);
                    });

            }, 60000);

            // Armazena o timeout para ser cancelado se necessário
            $targetEl.setAttribute('data-timeout-id', String(timeoutId));
        }

        // Função de contagem regressiva
        window.startCountdown = (seconds) => {
            const countdownElement = document.getElementById('countdown-timer');
            let remainingTime = seconds;

            const updateCountdown = () => {
                let minutes = Math.floor(remainingTime / 60);
                let seconds = remainingTime % 60;

                // Formata como 00:59
                countdownElement.innerText = `Você será desconectado em ${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

                if (remainingTime > 0) {
                    remainingTime--;
                    setTimeout(updateCountdown, 1000);
                }
            };

            updateCountdown();
        };

        window.handleUserResponse = async (stillUsing) => {
            const modalElement = document.getElementById('activity-confirmation');
            const timeoutId = modalElement.getAttribute('data-timeout-id');

            const modal = new Modal(modalElement);

            if (stillUsing) {
                try {
                    await axios.post('/keep-device-active', {
                        device_id: deviceId
                    });
                    clearTimeout(Number(timeoutId));
                    modal.hide();
                } catch (error) {
                    console.error('Erro ao manter dispositivo ativo:', error);
                }
            } else {
                try {
                    await axios.post('/disconnect-device', {
                        device_id: deviceId
                    });
                    location.reload();
                } catch (error) {
                    console.error('Erro ao desconectar dispositivo:', error);
                }
                clearTimeout(Number(timeoutId));
            }
        };
    }
});
